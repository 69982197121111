import "$styles/index.css";
import "$styles/syntax-highlighting.css";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

import { initLanguageSwitcher } from "./language_switcher";
import { initLanguageSelect } from "./language_select";

initLanguageSwitcher();
initLanguageSelect();

console.info("Bridgetown is loaded!");
